import logo from "./logo.png"

const clientData = {
    client_entity: 83,
    attorney_firm: 'ERC Funding Partners',
    attorney_name: 'Operations Team',
    attorney_number: '(601)955-1113',
    attorney_email: 'info@ercfundingpartners.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_don_williams%2B(1080p).mp4',
    mainColor: '#0000a0',
    secondaryColor: '#0000a0',
    siteLink: 'ercfundingpartners.com',
    logo
}

export default clientData